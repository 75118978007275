import React, { useEffect, useState } from 'react';
import info from "./info.json"

function ResumeSection() {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Simulate fetching data
    setData(info.resumeData);
  }, []);

  if (!data) {
    return <div>Loading...</div>; // Handle loading state
  }

  return (
    <section id="resume" className="resume" >
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Resume</h2>
        </div>

        <div className="row">
          {/* Left Column: Summary and Education */}
          <div className="col-lg-6">
            {/* Summary */}
            <h3 className="resume-title">Summary</h3>
            <div className="resume-item pb-0">
              <h4>{data.summary.name}</h4>
              <p><em>{data.summary.title} with {data.summary.experience}</em></p>
              <ul>
                {data.summary.contact.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            {/* Education */}
            <h3 className="resume-title">Education</h3>
            {data.education.map((edu, index) => (
              <div className="resume-item" key={index}>
                <h4>{edu.degree}</h4>
                <h5>{edu.period}</h5>
                <p><em>{edu.institution}</em></p>
              </div>
            ))}
          </div>

          {/* Right Column: Professional Experience */}
          <div className="col-lg-6">
            {/* Professional Experience */}
            <h3 className="resume-title">Professional Experience</h3>
            {data.experience.map((exp, index) => (
              <div className="resume-item" key={index}>
                <h4>{exp.title}</h4>
                <h5>{exp.period}</h5>
                <p><em>{exp.company}</em></p>
                <ul>
                  {exp.details.map((detail, idx) => (
                    <li key={idx}>{detail}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResumeSection;
