import React, { useEffect } from 'react';
import Typed from 'typed.js';
import data from "./info.json"
// JSON data
const heroData = data.heroData

function HeroSection() {
  useEffect(() => {
    const options = {
      strings: heroData.roles,
      typeSpeed: 50,
      backSpeed: 50,
      loop: true
    };
    const typed = new Typed('.typed', options);

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <section id="hero" className="d-flex flex-column justify-content-center">
      <div className="container" data-aos="zoom-in" data-aos-delay="100">
        <h1>{heroData.name}</h1>
        <p>I'm <span className="typed"></span></p>
        <div className="social-links">
          {heroData.socialLinks.map((link, index) => (
            <a key={index} href={link.url} className={link.platform}>
              <i className={`bx bxl-${link.platform}`}></i>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
