import React from 'react';
import data from "./info.json"
// JSON data
const navData = data.navData

function Header() {
  return (
    <header id="header" className="d-flex flex-column justify-content-center">
      <nav id="navbar" className="navbar nav-menu">
        <ul>
          {navData.map((item, index) => (
            <li key={index}>
              <a href={item.href} className="nav-link scrollto">
                <i className={item.icon}></i> <span>{item.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
