import React from 'react';
import data from "./info.json"

// Define the facts data in JSON format
const factsData = data.Facts

function FactsSection() {
  return (
    <section id="facts" className="facts">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Facts</h2>
          <p>Web and Mobile Developer with more than 2 years of experience in MERN stack. Based in Tunisia.</p>
        </div>
        <div className="row">
          {/* Map over the factsData JSON to render the facts dynamically */}
          {factsData.map((fact, index) => (
            <div key={index} className="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div className="count-box">
                <i className={fact.icon}></i>
                <span className="purecounter">{fact.count}</span>
                <p>{fact.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FactsSection;
