import React from 'react';

const CertificateCard = ({ title, description, date, detailsLink }) => {
  return (
    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
      <div className="icon-box iconbox-yellow">
        <div className="icon">
          <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
            <path stroke="none" strokeWidth="0" fill="#f5f5f5" d="M300,503.46388370962813C374.79870501325706,506.71871716319447,..."></path>
          </svg>
          <i className="bx bx-layer"></i>
        </div>
        <h4><a href={detailsLink}>{title}</a></h4>
        <p>{description}</p>
        <p>{date}</p>
      </div>
    </div>
  );
};

const CertificatesSection = () => {
  const certificates = [
    {
      title: "National License in Information System Development",
      description: "PFE Project with Very Good Score",
      date: "16/6/2023: ISET Kelibia",
      detailsLink: "#"
    },
    {
      title: "Cisco Networking Academy Certifications",
      description: "Certifications in Python/cybersecurity/network security",
      date: "August 2023: IT Department 4C KELIBIA, Online",
      detailsLink: "#"
    },
    {
      title: "AI Certificate",
      description: "Certifications: Artificial Intelligence And Its Tools Identification Application International Training Project",
      date: "06/2023: IT Department 4C KELIBIA",
      detailsLink: "#"
    },
    {
      title: "Mobile Development Certificate",
      description: "Mobile Development Certification with Kotlin Android",
      date: "2023: Made in Kélibia, Platform: https://fad.e-formation.tn/",
      detailsLink: "#"
    },
    {
      title: "Second Place in Chess Competition at ISET Kelibia",
      description: "2022: Chess competition at ISET Kelibia.",
      date: "",
      detailsLink: "#"
    }
  ];

  return (
    <section id="Certificates" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Certificates</h2>
          <p>Explore our diverse portfolio of high-impact projects spanning various industries, showcasing our expertise in delivering innovative solutions with exceptional quality and customer satisfaction.</p>
        </div>
        <div className="row">
          {certificates.map((certificate, index) => (
            <CertificateCard 
              key={index}
              title={certificate.title}
              description={certificate.description}
              date={certificate.date}
              detailsLink={certificate.detailsLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CertificatesSection;
