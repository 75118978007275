import React from 'react';
// Import JSON data
import data from './info.json'; // Adjust the path based on where you store the JSON file

function AboutSection({props}) {
  const aboutData = data.aboutData
  const factsData = data.Facts

  return (
    <section id="about" className="about" >
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{aboutData.sectionTitle}</h2>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <img 
              src={aboutData.profileImage} 
              className="img-fluid" 
              alt="" 
              style={{ borderRadius: "20px" }} 
            />
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content">
            <h3>{aboutData.jobTitle}</h3>
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  {aboutData.personalInfo.map((info, index) => (
                    <li key={index}>
                      <i className="bi bi-chevron-right"></i> 
                      <strong>{info.label}:</strong> 
                      <span>{info.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  {aboutData.professionalInfo.map((info, index) => (
                    <li key={index}>
                      <i className="bi bi-chevron-right"></i> 
                      <strong>{info.label}:</strong> 
                      <span>{info.value}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <p>{aboutData.description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
